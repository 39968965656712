<template>
  <div>
    <vx-card>
      <div class="vx-row find-people" id="v-step-0">
        <!-- Intent leads table-->
        <div class="vx-col xl:w-full col-cus-12 mb-8 w-full" >
          <div class="card-title btn-search w-full more-card-title flex flex-wrap pb-2 items-center">
            <div><h2>Intent Campaigns</h2></div>
            <vs-button
              title="Update"
              color="primary"
              type="filled"
              class="mr-2 mb-2 "
              @click="purchaseAdditionalCampaignPopup = true"
              >{{ LabelConstant.buttonLabelPurchaseAdditionalCampaigns }}
            </vs-button> 
          </div>
          <div class="flex btn-search w-full column-reverse flex-wrap  justify-between" >
            <vs-button
              title="Add Campaign"
              color="success"
              type="filled"
              icon-pack="feather"
              icon="icon-plus"
              class="mr-2 float-left mb-4"
              @click="addCampaignPopup = true, mode = 'add', setUnitedStatesDefault()"
              >{{ LabelConstant.buttonLabelAddCampaign }}</vs-button
            >
            <div class="pb-4">
               <p class="text-danger d-block">
                You are using {{customCampaignsProvisioned}} of {{customCampaignsIncluded + customCampaignsPurchased}} custom campaigns
              </p>
              <p class="text-danger d-block">
                You are using {{prebuiltCampaignsProvisioned}} of {{prebuiltCampaignsIncluded}} prebuilt campaigns
              </p>
            </div>
          </div>
          <div class="vx-row mb-3" >
            <div class="vx-col input-text w-full">
              <div class="vx-row" >
                <div class="vx-col w-full mt-6">
                  <!-- table -->
                  <vs-table
                    :data="campaigns"
                    pagination
                    :max-items="itemsPerPage"
                    search
                  >
                    <template slot="thead">
                      <vs-th></vs-th>
                      <vs-th sort-key="Campaign_Name">Campaign Name</vs-th>
                      <vs-th sort-key="workers">Crawlers</vs-th>
                      <vs-th sort-key="type">Type</vs-th>
                      <vs-th sort-key="keywords">Keyword</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td width="90">
                          <div class="table-action">
                            <vs-button
                              title="Edit"
                              color="primary"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-edit"
                              class="mr-2 float-left"
                              @click="editCampaign(tr)"
                            ></vs-button>
                            <vs-button
                              title="Delete"
                              color="danger"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-trash-2"
                              class="mr-0"
                              @click="deleteConfirm(tr)"
                            ></vs-button>
                          </div>
                        </vs-td>
                        <vs-td :data="data[indextr].Campaign_Name">
                          {{ data[indextr].Campaign_Name }}
                        </vs-td>
                        <vs-td :data="data[indextr].workers">
                          {{ data[indextr].workers }}
                        </vs-td>
                        <vs-td :data="data[indextr].type">
                          {{ data[indextr].type }}
                        </vs-td>
                        <vs-td :data="data[indextr].keywords">
                          <!-- {{ data[indextr].keywords }} -->
                          <span v-show="campaignsMoreOrLessArr[indextr] == 'less'">
                            {{data[indextr].keywords | onLoadChangeKeywordsMoreLess('less')}}
                          </span>
                          <span v-show="campaignsMoreOrLessArr[indextr] == 'less' && data[indextr].showMoreLessIcon"
                            class="more-icon" @click="changeKeywordsMoreLess('more', indextr, 'custom')">
                            <feather-icon
                              icon="InfoIcon"
                              title="View More"
                            ></feather-icon>
                          </span>
                          <span v-show="campaignsMoreOrLessArr[indextr] == 'more'">
                            {{data[indextr].keywords | onLoadChangeKeywordsMoreLess('more')}}
                          </span>
                          <span v-show="campaignsMoreOrLessArr[indextr] == 'more' && data[indextr].showMoreLessIcon"
                            class="more-icon" @click="changeKeywordsMoreLess('less', indextr, 'custom')">
                            <feather-icon
                              icon="ArrowLeftCircleIcon"
                              title="View Less"
                            ></feather-icon>
                          </span>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>

    <!-- Keyword Based Capaign List -->
    <vs-popup class="delete-popup" :active.sync="keywordBasedCampaignsPopup">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col text-left w-full">
          <div class="popup-icon">
              <feather-icon text-info icon="InfoIcon"></feather-icon>
            </div>
            <h4 class="text-primary">This keyword exists in the following pre built campaigns:</h4>
            <ul class="list-style-campaign">
              <li v-for="(keywordBasedCampaign, index) in keywordBasedCampaigns" :key="index">
                <span class="deleted-no">{{ keywordBasedCampaign }}</span>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button
          @click="closeKeywordBasedCampaignsPopup()"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelClose }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Confirmation Purchase or Cancel Additional Custom Campaign Popup --> 
    <vs-popup class="transaction-popup" :active.sync="addDeleteCustomCampaignConfirmActive">             
      <div class="transaction-details">
          <div class="popup-icon">
              <img v-show="userActionType == 'add'" src="../../assets/images/warning.svg">
              <img v-show="userActionType == 'delete'" src="../../assets/images/trash.svg">
          </div>
          <h2 v-show="userActionType == 'add'">Purchase Additional Custom Campaign?</h2>
          <h2 v-show="userActionType == 'delete'">Remove Custom Campaign?</h2>
          <p v-show="userActionType == 'add'">{{additionalCustomerCampaign}}</p>
          <p v-show="userActionType == 'delete'">{{deletionCustomCampaign}}</p>
          <div class="float-left mt-6 w-full text-center">             
              <vs-button @click="purchaseAdditionalCampaign()" v-show="userActionType == 'add'" color="success" class="mt-0 sm:w-auto w-full" type="filled">{{ LabelConstant.buttonLabelPurchase }}</vs-button>
              <vs-button @click="deletePurchasedCampaign()" v-show="userActionType == 'delete'" color="danger"  class="mt-0 sm:w-auto w-full"  type="filled">{{ LabelConstant.buttonLabelRemove }}</vs-button>
              <vs-button @click="addDeleteCustomCampaignConfirmActive=false" color="grey" type="border" class="grey-btn sm:ml-4  ml-0 mt-2 sm:mt-0 sm:w-auto w-full">{{ LabelConstant.buttonLabelCancel }}</vs-button>            
          </div>
      </div>            
    </vs-popup>

    <!-- Purchase or Cancel Additional Custom Campaigns popup -->
    <vs-popup class="popup-width" title="Purchase or Cancel Additional Custom Campaigns" :active.sync="purchaseAdditionalCampaignPopup">
        <template v-if="!isActiveFreeTrial && !isReseller">
            <div class="vx-row mb-3" >
                <div class="vx-col md:w-1/2 w-full">
                    <ul class="account-summary p-4">
                        <li class="pb-2 w-full">
                            <h4>Custom Campaigns included with plan</h4>
                            <span>{{customCampaignsIncluded}}</span>
                        </li>
                        <li class="pl-0 pb-2 w-full">
                            <h4>Additional Custom Campaigns purchased</h4>
                            <span>{{customCampaignsPurchased}}</span>
                        </li>
                        <li class="w-full pb-2">
                            <h4>Total Custom Campaigns Provisioned</h4>
                            <span>{{customCampaignsProvisioned}}</span>                      
                        </li>
                    </ul>
                </div>
                <div class="vx-col w-full option-section">
                    <div class="card-title">
                        <h2> Options </h2>                        
                    </div>
                    <div class="vx-row">
                        <div class="vx-col w-full mb-8">
                            <div class="option-item">
                                <vs-radio v-model="userActionType" vs-value="add" :color="addCustomCampaignRadioColor" @change="changeColor()"> 
                                  <span :class="addCustomCampaignTextColor">I would like to purchase an additional custom campaign</span>
                                </vs-radio>
                                <ul>
                                    <li>
                                        <span>Purchase additional custom Campaigns</span>
                                        <v-select
                                          v-model="additionalCustomerCampaign"
                                          :options="additionalCustomCampaignOptions"
                                          :clearable="false"
                                        />
                                        <span>$199/month</span>
                                    </li>                                
                                </ul>
                            </div>
                        </div>
                        <div class="vx-col w-full mb-8" v-show="campaignsAvailableToDelete > 0">
                        <!-- <div class="vx-col w-full mb-8"> -->
                            <div class="option-item">
                                <vs-radio v-model="userActionType" vs-value="delete" :color="deleteCustomCampaignRadioColor" @change="changeColor()">
                                  <span :class="deletionCustomCampaignTextColor">I would like to cancel an additional custom campaign</span>
                                </vs-radio>
                                <ul>
                                    <li>
                                        <span>Cancel additional custom Campaigns</span>
                                        <v-select 
                                          v-model="deletionCustomCampaign" 
                                          :options="deletionCustomCampaignOptions" 
                                          :clearable="false"
                                        />
                                        <span>$-199/month</span>
                                    </li>                                
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="isActiveFreeTrial && !isReseller">
          <div class="vx-row mb-3">
            <div class="vx-col text-left w-full">
            <div class="popup-icon">
                <feather-icon text-info icon="InfoIcon"></feather-icon>
              </div>
              <h4 class="text-primary">This feature is not available for free trials. Please contact your administrator</h4>
            </div>
          </div>
        </template>
        <template v-if="isReseller">
          <div class="vx-row mb-3">
            <div class="vx-col text-left w-full">
            <div class="popup-icon">
                <feather-icon text-info icon="InfoIcon"></feather-icon>
              </div>
              <h4 class="text-primary">Please contact your administrator for additional purchases</h4>
            </div>
          </div>
        </template>
        <div class="float-right mb-8">
            <vs-button v-if="!isActiveFreeTrial && !isReseller" @click="addDeleteCustomCampaignConfirmActive=true" v-show="userActionType == 'add'" type="filled" color="primary">Purchase Additional Campaigns</vs-button>
            <vs-button v-if="!isActiveFreeTrial && !isReseller" @click="addDeleteCustomCampaignConfirmActive=true" v-show="userActionType == 'delete'" color="danger" type="filled">Remove Additional Campaigns</vs-button>
            <vs-button @click="cancelPurchase()" color="grey" type="border" class="ml-4 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>  
    </vs-popup>
    
    <!--Add/edit  Campaign  modal -->
    <vs-popup
      class="popup-width intent-champain-popup  popup-width-lg"
      title="Campaign"
      :active.sync="addCampaignPopup"
    >
      <template>
        <div id="page-user-view">
          <!-- tabs -->
          <vs-tabs class="steps-tab contacts-tabs camp-tab" v-model="mainActivetab">
            <!-- tab Select -->
            <vs-tab label="Select" @click="mainActivetab = 0">
              <div class="campaign-second">
                <div class="card-title my-8">
                  <h2>Select a Campaign Type</h2>
                </div>
                <div class="select-campaign-tabs">
                  <div class="vx-row">
                    <!--  left side-->
                    <div class="vx-col md:w-1/4 mb-8 w-full">
                      <ul class="select-tabs">
                        <li v-bind:class="campaignType == 1 ? 'active' : ''" @click="resetCampaignTypeSelection(1)">
                          <h5>Custom Campaign</h5>
                          <p>Create your own campaign using terms specific to your product or industry.</p>
                        </li>
                        <li v-bind:class="campaignType == 2 ? 'active' : ''" @click="(prebuiltCampaignsIncluded - prebuiltCampaignsProvisioned) > 0 ? resetCampaignTypeSelection(2) : prebuiltCampaignOverWarning = true">
                          <h5>Prebuilt Campaign</h5>
                          <p>These campaigns have been previously created based on industry specific keywords.</p>
                        </li>
                      </ul>
                    </div>
                    <!--  content right side -->
                    <div class="vx-col md:w-3/4 mb-8 w-full">
                      <!-- Custom Campaign -->
                      <section class="select-content mb-8" v-show="campaignType == 1 && customCampaignsAvailableToProvision > 0">
                        <a @click="nextStep()" >
                          <div class="vx-row items-center cursor-pointer">
                            <div class="vx-col w-full text-center">
                              <div class="custom-campaign-text w-full text-center">
                                <h3 class="text-primary pb-5">Build your own campaign with your industry keywords.</h3>
                                <div class="build-img">
                                  <img
                                    src="../../../src/assets/images/build-page.png"
                                    class="d-inline-block w-full"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </section>
                      <!-- section-2 -->
                      <section class="select-content mb-8" v-show="campaignType == 1 && customCampaignsAvailableToProvision <= 0">
                        <!-- <a @click="nextStep()" > -->
                          <div class="vx-row items-center">
                            <div class="vx-col w-full text-center">
                              <div class="custom-campaign-text w-full text-center">
                                <h3 class="text-primary pb-5">Build your own campaign with your industry keywords.</h3>
                                <div class="build-img ">
                                  <img
                                    src="../../../src/assets/images/build-page.png"
                                    class="d-inline-block w-1/2"
                                  />
                                </div>
                                <h6 class="px-8 py-5 text-danger">You have used all of your custom campaigns.  Please purchase additional crawlers.</h6>
                                 <vs-button
                                    v-show="customCampaignsAvailableToProvision <= 0"
                                    @click="purchaseAdditionalCampaignPopup = true"
                                    title="Purchase Additional Campaign Crawlers"
                                    color="primary"
                                    type="filled"
                                    >Purchase Additional Campaign Crawlers</vs-button
                                  >
                              </div>
                            </div>
                          </div>
                        <!-- </a> -->
                      </section>
                       <!-- end section-2 -->
                      <!--  Prebuilt Campaign-->
                      <section class="select-content mb-8" v-show="campaignType == 2">
                        <div class="vx-col w-full">
                          <vs-button
                            title="Export"
                            color="primary"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-download"
                            class="mr-2 mb-5 float-left"
                            >
                            <download-excel class="btn btn-default" :data="excelData" :exportFields="excelFields" worksheet="Forms Captured" name="Prebuilt Campaigns.xls">
                              {{ LabelConstant.buttonLabelExport }}
                            </download-excel>
                          </vs-button>
                        </div>
                        <div class="common-table find-people">
                          <div class="table-row-select">
                            <vs-select
                              :label="LabelConstant.inputLabelShowEntries"
                              class="show-select"
                              v-model="prebuiltCampaignPerPage"
                            >
                              <vs-select-item
                                :key="index"
                                :value="item.value"
                                :text="item.text"
                                v-for="(item, index) in recordList"
                              />
                            </vs-select>
                          </div>
                          <!-- table -->
                          <vs-table id="tableDataPrebuiltCampaign" ref="tableDataPrebuiltCampaign" :data="prebuiltCampaignsList" :max-items="prebuiltCampaignPerPage" pagination search>
                            <template slot="thead">
                              <vs-th>Actions</vs-th>
                              <vs-th sort-key="Campaign_Name">Name</vs-th>
                              <vs-th sort-key="Category">Category</vs-th>
                              <vs-th sort-key="keywords">Keywords</vs-th>
                            </template>
                            <template slot-scope="{ data }">
                              <vs-tr
                                v-bind:class="prebuiltCampaignId == data[indextr].Id ? 'bg-fourth' : ''"
                                :data="tr"
                                :key="indextr"
                                v-for="(tr, indextr) in data"
                              >
                                <vs-td width="50" class="text-center" >
                                  <div class="table-action w-auto">
                                    <vs-button
                                      title="Select"
                                      color="warning"
                                      type="filled"
                                      icon-pack="feather"
                                      icon="icon-copy"
                                      class="mr-0"
                                      @click="selectPrebuiltCampaign(data[indextr])"
                                    ></vs-button>
                                  </div>
                                </vs-td>
                                <vs-td width="150" :data="data[indextr].Campaign_Name" class="word-break-all">
                                  {{ data[indextr].Campaign_Name }}
                                </vs-td>
                                <vs-td width="150" :data="data[indextr].Category" class="word-break-all">
                                  {{ data[indextr].Category }}
                                </vs-td>
                                <vs-td :data="data[indextr].keywords" class="word-break-all">
                                  <!-- {{ data[indextr].keywords }} -->
                                  <span v-show="prebuiltCampaignsMoreOrLessArr[indextr] == 'less'">
                                    {{data[indextr].keywords | onLoadChangeKeywordsMoreLess('less')}}
                                  </span>
                                  <span v-show="prebuiltCampaignsMoreOrLessArr[indextr] == 'less' && data[indextr].showMoreLessIcon"
                                    class="more-icon" @click="changeKeywordsMoreLess('more', indextr, 'prebuilt')">
                                    <feather-icon
                                      icon="InfoIcon"
                                      title="View More"
                                    ></feather-icon>
                                  </span>
                                  <span v-show="prebuiltCampaignsMoreOrLessArr[indextr] == 'more'">
                                    {{data[indextr].keywords | onLoadChangeKeywordsMoreLess('more')}}
                                  </span>
                                  <span v-show="prebuiltCampaignsMoreOrLessArr[indextr] == 'more' && data[indextr].showMoreLessIcon"
                                    class="more-icon" @click="changeKeywordsMoreLess('less', indextr, 'prebuilt')">
                                    <feather-icon icon="ArrowLeftCircleIcon" title="View Less"></feather-icon>
                                  </span>
                                </vs-td>
                              </vs-tr>
                            </template>
                          </vs-table>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </vs-tab>
            <!-- tab genaral -->
            <vs-tab label="General" @click="mainActivetab = 1">
              <div class="campaign-second">
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="from-to-camp">
                      <form data-vv-scope="campaignValidate">
                        <div class="vx-row">
                          <!-- Campaign Name -->
                          <div class="vx-col w-full mb-1">
                            <div class="filter-campaign">
                              <h3>Campaign Name</h3>
                              <vs-input
                                class="w-full"
                                v-model="campaignName"
                                v-validate="'required'"
                                name="campaignName"
                              />
                              <span class="text-danger text-sm mb-3 float-left w-full">{{ errors.first("campaignValidate.campaignName") }}</span>
                            </div>
                          </div>
                          <!-- Description -->
                          <div class="vx-col w-full mb-1">
                            <div class="filter-campaign">
                              <h3>Description</h3>
                              <vs-textarea
                                class="form-textarea"
                                placeholder="Type here"
                                v-model="description"
                              />
                            </div>
                          </div>
                          <!-- Crawlers -->
                          <div class="vx-col w-full mb-1" v-if="campaignType == 1">
                            <div class="filter-campaign">
                              <h3>Crawler</h3>
                              <div class="add-to-field mb-3">
                                <div class="float-left flex items-center w-full flex-wrap justify-between">
                                  <v-select
                                    v-show="mode == 'add' && customCampaignsAvailableToProvision > 0"
                                    class="md:w-2/5 w-full"
                                    label="Value"
                                    :options="crawlerOptions"
                                    v-model="worker"
                                    :reduce="option => option.Key"
                                    :clearable="false"
                                  />
                                  <v-select
                                    v-show="mode == 'edit'"
                                    class="md:w-2/5 w-full"
                                    label="Value"
                                    :options="crawlerOptions"
                                    v-model="worker"
                                    :reduce="option => option.Key"
                                    :disabled="customCampaignsAvailableToProvision <= 0"
                                    :clearable="false"
                                  />
                                  <!-- <vs-button
                                    v-show="customCampaignsAvailableToProvision <= 0"
                                    @click="purchaseAdditionalCampaignPopup = true"
                                    title="Purchase Additional Campaign Workers"
                                    color="primary"
                                    type="filled"
                                    >Purchase Additional Campaign
                                    Crawlers</vs-button
                                  > -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </vs-tab>
            <!-- tab triggers -->
            <vs-tab label="Triggers" @click="mainActivetab = 2">
              <form data-vv-scope="triggerValidate">
                <div class="campaign-second">
                  <div class="vx-row">
                    <div class="vx-col w-full">
                      <p class="text-danger pt-2 d-block">{{selectedKeyword}} of 50 selections used</p>
                      <div class="from-to-camp">
                        <div class="vx-row">
                          <!-- Keywords -->
                          <div class="vx-col w-full mb-8">
                            <div class="send-camp">
                              <div class="vx-row">
                                <div class="vx-col w-full">
                                  <h3>Keywords</h3>
                                </div>
                                <div class="vx-col md:w-1/2 w-full find-people">
                                  <div class="add-to-field mt-0 pt-0">
                                    <div class="float-left flex items-center w-full flex-wrap">
                                      <v-select
                                        ref="keyworddd"
                                        class="w-full mb-3 input-color"
                                        label="Value"
                                        placeholder="Enter keyword here "
                                        :options="keywordsOptions"
                                        v-model="keywordSelected"
                                        :clearSearchOnSelect="false"
                                        @input="clearSearchedTerm('keyword')"
                                        :disabled="campaignType == 2 || selectedKeyword >= 50 ? true : false"
                                        @keyup.native="getTiggerItemByTypeTerm('keyword',$event)"
                                      />
                                      <vs-button
                                        title="Update"
                                        color="primary"
                                        class="ml-auto"
                                        type="filled"
                                        @click="requestNewTrigger('keyword')"
                                        :disabled="campaignType == 2 || selectedKeyword >= 50 ? true : false"
                                      >
                                        {{ LabelConstant.buttonLabelRequestNewKeyword }}
                                      </vs-button>
                                      <vs-button
                                        title="Update"
                                        color="success"
                                        class="ml-2"
                                        type="filled"
                                        @click="addKeywords()"
                                        :disabled="campaignType == 2 || selectedKeyword >= 50 ? true : false"
                                      >
                                        {{ LabelConstant.buttonLabelAdd }}
                                      </vs-button>
                                    </div>
                                  </div>
                                </div>
                                <div class="vx-col md:w-1/2 w-full find-people">
                                  <input-tag
                                    v-model="keywordToshow"
                                    @input="updateKeywordTags"
                                    :read-only="campaignType == 2 ? true : false"
                                    v-validate="competitorsToshow.length == 0 ? 'required' : ''"
                                    onkeypress="return false;"
                                    name="keywordToshow"
                                    class="h-min-100"
                                  ></input-tag>
                                  <span class="text-danger text-sm">{{ errors.first("triggerValidate.keywordToshow") }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Engaged with competitors -->
                          <div class="vx-col w-full mb-8">
                            <div class="send-camp">
                              <div class="vx-row">
                                <div class="vx-col w-full">
                                  <h3>Engaged with Competitors</h3>
                                </div>
                                <div class="vx-col md:w-1/2 w-full find-people">
                                  <div class="add-to-field mt-0 pt-0">
                                    <div class="float-left flex items-center w-full flex-wrap">
                                      <v-select
                                        ref="competitors"
                                        class="w-full mb-3 input-color"
                                        label="Value"
                                        placeholder="Enter competitor domain here "
                                        :options="compititorsOptions"
                                        v-model="competitorsSelected"
                                        :clearSearchOnSelect="false"
                                        @input="clearSearchedTerm('compitetor')"
                                        :disabled="campaignType == 2 || selectedKeyword >= 50 ? true : false"
                                        @keyup.native="getTiggerItemByTypeTerm('compitetor',$event)"
                                      />
                                      <vs-button
                                        title="Update"
                                        color="primary"
                                        class="ml-auto"
                                        type="filled"
                                        @click="requestNewTrigger('competitor')"
                                        :disabled="campaignType == 2 || selectedKeyword >= 50 ? true : false"
                                      >
                                        {{ LabelConstant.buttonLabelRequestNewCompetitor }}
                                      </vs-button>
                                      <vs-button
                                        title="Update"
                                        color="success"
                                        class="ml-2"
                                        type="filled"
                                        @click="addCompitotors()"
                                        :disabled="campaignType == 2 || selectedKeyword >= 50 ? true : false"
                                      >
                                        {{ LabelConstant.buttonLabelAdd }}
                                      </vs-button>
                                    </div>
                                  </div>
                                </div>
                                <div class="vx-col md:w-1/2 w-full find-people">
                                  <input-tag
                                    v-model="competitorsToshow"
                                    @input="updateCompetitorsTags"
                                    :read-only="campaignType == 2 ? true : false"
                                    onkeypress="return false;"
                                    v-validate="keywordToshow.length == 0 ? 'required' : ''"
                                    name="competitorsToshow"
                                    class="h-min-100"
                                  ></input-tag>
                                  <span class="text-danger text-sm">{{ errors.first("triggerValidate.competitorsToshow") }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Categories -->
                          <div class="vx-col w-full mb-8">
                            <div class="send-camp">
                              <div class="vx-row">
                                <div class="vx-col w-full">
                                  <h3>Categories</h3>
                                </div>
                                <div class="vx-col md:w-1/2 w-full find-people">
                                  <div class="add-to-field mt-0 pt-0">
                                    <div class="float-left flex items-center w-full flex-wrap">
                                      <v-select
                                        ref="categories"
                                        class="w-full mb-3"
                                        label="Value"
                                        placeholder="Select "
                                        :options="categoriesOption"
                                        v-model="categoriesSelected"
                                        :disabled="campaignType == 2 || selectedKeyword >= 50 ? true : false"
                                        @keyup.native="getTiggerItemByTypeTerm('category',$event)"
                                      />
                                      <vs-button
                                        title="Update"
                                        color="success"
                                        class="ml-auto"
                                        type="filled"
                                        @click="addCategory()"
                                        :disabled="campaignType == 2 || selectedKeyword >= 50 ? true : false"
                                      >
                                      {{ LabelConstant.buttonLabelAdd }}
                                      </vs-button>
                                    </div>
                                  </div>
                                </div>
                                <div class="vx-col md:w-1/2 w-full find-people">
                                  <input-tag
                                    v-model="categoriesToShow"
                                    @input="updateCategoriesTags"
                                    :read-only="campaignType == 2 ? true : false"
                                    onkeypress="return false;"
                                    name="categoriesToShow"
                                     class="h-min-100"
                                  ></input-tag>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </vs-tab>
            <!-- tab filter -->
            <vs-tab label="Filters" @click="mainActivetab = 3">
              <div class="report-list">
                <div class="row">
                  <div class="vx-col w-full find-people">
                    <!-- States -->
                    <div class="filter-campaign px-0 vx-row ">
                      <div class="vx-col w-full ">
                        <h3>States</h3>
                      </div>
                      <div class="vx-col md:w-1/2 w-full ">
                        <div class="add-to-field pt-0 mt-0">
                          <div class=" float-left flex items-center w-full flex-wrap">
                            <v-select
                              class="w-full mb-3"
                              label="Key"
                              placeholder="Select"
                              v-model="statesSelect"
                              :options="statesOptions"
                            />
                            <vs-button
                              title="Update"
                              color="success"
                              class="ml-auto"
                              type="filled"
                              @click="addItemsToShow('state')"
                              >{{ LabelConstant.buttonLabelAdd }}</vs-button
                            >
                          </div>
                        </div>
                      </div>
                      <div class="vx-col md:w-1/2 w-full ">
                        <input-tag
                          v-model="statesToShow"
                          @input="updateStatesTags"
                          onkeypress="return false;"
                          class="h-min-100"
                        ></input-tag>
                      </div>
                    </div>
                    <!-- Countries -->
                    <div class="filter-campaign px-0 vx-row ">
                      <div class="vx-col w-full ">
                        <h3>Countries</h3>
                      </div> 
                      <div class="vx-col md:w-1/2 w-full ">
                        <div class="add-to-field mt-0 pt-0">
                          <div class=" float-left flex items-center w-full flex-wrap">
                            <v-select
                              class="w-full mb-3"
                              label="Key"
                              placeholder="Select "
                              :options="countriesOptions"
                              v-model="countriesSelected"
                            />
                            <vs-button
                              title="Update"
                              color="success"
                              class="ml-auto"
                              type="filled"
                              @click="addItemsToShow('country')"
                              >{{ LabelConstant.buttonLabelAdd }}</vs-button
                            >
                          </div>
                        </div>
                      </div>
                      <div class="vx-col md:w-1/2 w-full ">
                        <input-tag
                          v-model="countriesToShow"
                          @input="updateCoutriesTags"
                          onkeypress="return false;"
                          class="h-min-100"
                        ></input-tag>
                      </div>
                    </div>
                    <!--Industries -->
                    <div class="filter-campaign px-0 vx-row ">
                      <div class="vx-col w-full ">
                        <h3>Industries</h3>
                      </div>
                      <div class="vx-col md:w-1/2 w-full ">
                        <div class="add-to-field pt-0 mt-0">
                          <div class="float-left flex items-center w-full flex-wrap">
                            <v-select
                              class="w-full mb-3"
                              label="Key"
                              placeholder="Select "
                              :options="industryOptions"
                              v-model="industriesSelected"
                            />
                            <vs-button
                              title="Update"
                              color="success"
                              class="ml-auto"
                              type="filled"
                              @click="addItemsToShow('industry')"
                              >{{ LabelConstant.buttonLabelAdd }}</vs-button
                            >
                          </div>
                        </div>
                      </div>
                      <div class="vx-col md:w-1/2 w-full ">
                        <input-tag
                          v-model="industriesToShow"
                          @input="updateIndustryTags"
                          onkeypress="return false;"
                          class="h-min-100"
                        ></input-tag>
                      </div>
                    </div>
                    <!--Revenue Range -->
                    <div class="filter-campaign px-0 vx-row ">
                      <div class="vx-col w-full ">
                        <h3>Revenue Range</h3>
                      </div> 
                      <div class="vx-col md:w-1/2 w-full ">
                      <div class="add-to-field mt-0 pt-0">
                        <div class="float-left flex items-center w-full flex-wrap">
                          <v-select
                            class="w-full mb-3"
                            label="Key"
                            placeholder="Select"
                            :options="revenueRangeOptions"
                            v-model="revenueSelected"
                          />
                          <vs-button
                            title="Update"
                            color="success"
                            class="ml-auto"
                            type="filled"
                            @click="addItemsToShow('revenue')"
                            >{{ LabelConstant.buttonLabelAdd }}</vs-button
                          >
                        </div>
                      </div>
                      </div>
                      <div class="vx-col md:w-1/2 w-full ">
                        <input-tag
                          v-model="revenueToShow"
                          @input="updateRevenueTags"
                          onkeypress="return false;"
                          class="h-min-100"
                        ></input-tag>
                      </div>
                    </div>
                    <!--Employee Size -->
                    <div class="filter-campaign px-0 vx-row ">
                      <div class="vx-col w-full ">
                        <h3>Employee Size</h3>
                      </div>
                      <div class="vx-col md:w-1/2 w-full ">
                        <div class="add-to-field mt-0 pt-0">
                          <div class="float-left flex items-center w-full flex-wrap">
                            <v-select
                              class="w-full mb-3"
                              label="Value"
                              placeholder="Select "
                              :options="employeeSizeOptions"
                              v-model="employeeSizeSelected"
                            />
                            <vs-button
                              title="Update"
                              color="success"
                              class="ml-auto"
                              type="filled"
                              @click="addItemsToShow('employee')"
                              >{{ LabelConstant.buttonLabelAdd }}</vs-button
                            >
                          </div>
                        </div>
                      </div>
                      <div class="vx-col md:w-1/2 w-full ">
                        <input-tag
                          v-model="employeeToShow"
                          @input="updateEmplyoeeTags"
                          onkeypress="return false;"
                          class="h-min-100"
                        ></input-tag>
                      </div>
                    </div>
                    <!--Exclude Industries -->
                    <div class="filter-campaign px-0 vx-row">
                      <div class="vx-col w-full ">
                        <h3>Exclude Industries</h3>
                      </div>
                      <div class="vx-col md:w-1/2 w-full ">
                        <div class="add-to-field mt-0 pt-0">
                          <div class="float-left flex items-center w-full flex-wrap">
                            <v-select
                              class="w-full mb-3"
                              label="Value"
                              placeholder="Select "
                              :options="industryOptions"
                              v-model="excludeIndustriesSelected"
                            />
                            <vs-button
                              title="Update"
                              color="success"
                              class="ml-auto"
                              type="filled"
                              @click="addItemsToShow('excludeIdustries')"
                              >{{ LabelConstant.buttonLabelAdd }}</vs-button
                            >
                          </div>
                        </div>
                      </div>
                      <div class="vx-col md:w-1/2 w-full ">
                        <input-tag
                          v-model="excludeIdustriesToShow"
                          @input="updateExcludeIndustriesTags"
                          onkeypress="return false;"
                          class="h-min-100"
                        ></input-tag>
                      </div>
                    </div>
                    <!--Postal Code -->
                    <div class="filter-campaign">
                      <div class="flex align-center justify-between flex-wrap">
                        <h3>Postal Code</h3>
                        <p>Enter one per line</p>
                      </div>
                      <vs-textarea
                        class="form-textarea"
                        v-model="postalCodes"
                        placeholder="Type here"
                      />
                    </div>
                    <!--Exclude Currunt Customer -->
                    <div class="filter-campaign">
                      <div class="flex align-center justify-between flex-wrap">
                        <h3>Exclude Current Customers</h3>
                        <p>Enter one per line</p>
                      </div>
                      <vs-textarea
                        class="form-textarea"
                        v-model="excludeCurrentCustomer"
                        placeholder="Type here"
                      />
                    </div>
                    <!--Exclude Domains -->
                    <div class="filter-campaign">
                      <div class="flex align-center justify-between flex-wrap">
                        <h3>Exclude Domains</h3>
                        <p>Enter one per line</p>
                      </div>
                      <vs-textarea
                        class="form-textarea"
                        v-model="excludeDomains"
                        placeholder="Type here"
                      />
                    </div>
                  </div>
                </div>
              </div>     
            </vs-tab>
            <!-- tab contacts -->
            <!-- <vs-tab label="Contacts" @click="mainActivetab = 3">
              <div class="campaign-second">
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="from-to-camp">
                      <div class="vx-row">
                        
                        <div class="vx-col w-full mb-8">
                          <div class="send-camp">
                            <div class="vx-row">
                              <div class="vx-col w-full">
                                <h3>Job Titles</h3>
                              </div>
                              <div class="vx-col w-full find-people">
                                <input-tag v-model="jobsAdded"></input-tag>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vs-tab> -->
            <vs-tab label="Notifications" @click="mainActivetab = 4">
              <div class="config-notification pt-8">
                <div class="vx-row">
                  <div class="vx-col xl:w-1/3 lg-cus-6 w-full mb-6">
                    <vs-checkbox v-model="emailDailyreport" class="mb-4 ml-0">Email the following people a Daily Report</vs-checkbox>
                    <div v-show="emailDailyreport">
                      <div
                        class="email-box mb-4"
                        v-for="(daily, index) in dailyEmails"
                        :key="index"
                      >
                        <label>Email</label>
                        <vs-input v-model="daily.demail" />
                        <vs-button
                          color="success"
                          type="filled"
                          icon-pack="feather"
                          icon="icon-plus"
                          class="ml-2"
                          @click="addEmail('D')"
                        ></vs-button>
                        <vs-button
                          v-if="dailyEmails.length > 1"
                          color="danger"
                          type="filled"
                          icon-pack="feather"
                          icon="icon-minus"
                          class="ml-2"
                          @click="removeEmail('D', index)"
                        ></vs-button>
                      </div>
                    </div>
                  </div>
                  <div class="vx-col xl:w-1/3 lg-cus-6 w-full mb-6">
                    <vs-checkbox v-model="emailWeeklyreport" class="mb-4 ml-0">Email the following people a Weekly Report</vs-checkbox>
                    <div v-show="emailWeeklyreport">
                      <div
                        class="email-box mb-4"
                        v-for="(week, index) in weeklyEmails"
                        :key="index"
                      >
                        <label>Email</label>
                        <vs-input v-model="week.wemail" />
                        <vs-button
                          color="success"
                          type="filled"
                          icon-pack="feather"
                          icon="icon-plus"
                          class="ml-2"
                          @click="addEmail('W')"
                        ></vs-button>
                        <vs-button
                          v-if="weeklyEmails.length > 1"
                          color="danger"
                          type="filled"
                          icon-pack="feather"
                          icon="icon-minus"
                          class="ml-2"
                          @click="removeEmail('W', index)"
                        ></vs-button>
                      </div>
                    </div>
                  </div>
                  <div class="vx-col xl:w-1/3 lg-cus-6 w-full mb-6">
                    <vs-checkbox v-model="emailMonthlyreport" class="mb-4 ml-0">Email the following people a Monthly Report</vs-checkbox>
                    <div v-show="emailMonthlyreport">
                      <div
                        class="email-box mb-4"
                        v-for="(month, index) in monthlyEmails"
                        :key="index"
                      >
                        <label>Email</label>
                        <vs-input v-model="month.memail" />
                        <vs-button
                          color="success"
                          type="filled"
                          icon-pack="feather"
                          icon="icon-plus"
                          class="ml-2"
                          @click="addEmail('M')"
                        ></vs-button>
                        <vs-button
                          v-if="monthlyEmails.length > 1"
                          color="danger"
                          type="filled"
                          icon-pack="feather"
                          icon="icon-minus"
                          class="ml-2"
                          @click="removeEmail('M', index)"
                        ></vs-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vs-tab>
          </vs-tabs>
          <!-- end tabs -->
        </div>
      </template>
      <div class="flex w-full float-left mt-6 mb-8">
        <vs-button
          title="Update"
          color="primary"
          type="filled"
          class="mr-2"
          @click="stepBack()"
          v-if="(mode == 'edit' && mainActivetab != 0 && mainActivetab != 1) || (mode != 'edit' && mainActivetab != 0)"
          >{{ LabelConstant.buttonLabelBack }}</vs-button
        >
        <vs-button
          title="Update"
          color="primary"
          type="filled"
          class="ml-auto mr-2"
          @click="nextStep()"
          :disabled="(campaignType == 1 && customCampaignsAvailableToProvision == 0 && prebuiltCampaignId == 0 && mode != 'edit') || (campaignType == 2 && prebuiltCampaignId == 0)"
        >
            {{ mainActivetab == 4 ? LabelConstant.buttonLabelFinish : LabelConstant.buttonLabelNext }}
        </vs-button>
        <vs-button
          color="grey"
          @click="cancelCampaign()"
          type="border"
          class="grey-btn mr-0"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
      <!-- Purchase additional crawlers warning -->
      <vs-popup class="delete-popup" :active.sync="crawlerPurchaseMessagePopupActive">
        <template>
          <div class="vx-row mb-3">
            <div class="vx-col text-left w-full">
            <div class="popup-icon">
                <feather-icon text-info icon="InfoIcon"></feather-icon>
              </div>
              <h4 class="text-primary">Please purchase an additional ‘crawler’ to continue.  Each campaign must have a minimum of 1 crawler.</h4>
            </div>
          </div>
        </template>
        <div class="text-center mt-6 mb-8">
          <vs-button
            @click="crawlerPurchaseMessagePopupActive = false"
            color="grey"
            type="border"
            class="ml-4 grey-btn"
            >{{ LabelConstant.buttonLabelClose }}</vs-button
          >
        </div>
      </vs-popup>
      <!-- Prebuilt Campaign over warning -->
      <vs-popup class="delete-popup" :active.sync="prebuiltCampaignOverWarning">
        <template>
          <div class="vx-row mb-3">
            <div class="vx-col text-left w-full">
            <div class="popup-icon">
                <feather-icon text-info icon="InfoIcon"></feather-icon>
              </div>
              <h4 class="text-primary">You have used all of your available ‘Prebuilt Campaigns'.  Please contact your administrator for additional options.</h4>
            </div>
          </div>
        </template>
        <div class="text-center mt-6 mb-8">
          <vs-button
            @click="prebuiltCampaignOverWarning = false"
            color="grey"
            type="border"
            class="ml-4 grey-btn"
            >{{ LabelConstant.buttonLabelClose }}</vs-button
          >
        </div>
      </vs-popup>
    </vs-popup>

    <!-- Delete Search saved Popup -->
    <vs-popup class="delete-popup" :active.sync="deleteCampaignPopupActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Delete Campaign?</h4>
            <span class="deleted-no">{{ deleteCampaignName ? deleteCampaignName : "" }}</span>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button type="filled" @click="deleteCampaign()" color="danger">{{ LabelConstant.buttonLabelDelete }}</vs-button>
        <vs-button
          @click="cancelDeleteCampagin()"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import CollapseAccordion from "../../components/collapse/CollapseAccordion";
import InputTag from "vue-input-tag";
import { BMedia, BImg } from "bootstrap-vue";
import { Validator } from "vee-validate";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import VxCard from "../../components/vx-card/VxCard.vue";
import JsonExcel from "vue-json-excel";
//import Cookie from "js-cookie";

const dict = {
  custom: {
    campaignName: {
      required: "Please enter campaign name",
    },
    keywordToshow: {
      required: "Keywords are required please add keywords",
    },
    competitorsToshow: {
      required: "Competitors are required please add competitors",
    },
  },
};
Validator.localize("en", dict);
export default {
  components: {
    CollapseAccordion,
    InputTag,
    BMedia,
    BImg,
    "v-select": vSelect,
    Datepicker,
    VxCard,
    downloadExcel: JsonExcel,
  },
  filters: {
    onLoadChangeKeywordsMoreLess: function (params, moreLess) {
      var keywordsArray = params.split(", ");
      var lessKeyWords = "";
      for (let i = 0; i < keywordsArray.length; i++) {
        lessKeyWords +=
          i == keywordsArray.length
            ? keywordsArray[i]
            : ", " + keywordsArray[i];
        if (moreLess == "less") {
          if (i == 8) {
            if (keywordsArray.length > 9) {
              lessKeyWords += ",...";
            }
            break;
          }
        }
      }
      return lessKeyWords.substring(1);
    },
  },
  data: () => ({

    isReseller: false,
    isActiveFreeTrial: false,
    excelFields: {
      "Name": "Campaign_Name",
      "Category": "Category",
      "Description": "Description",
      "Keyword": "keywords",
    },
    excelData: [],
    json_meta: [
      [
      {
          key: "charset",
          value: "utf-8"
      }
      ]
    ],

    purchaseAdditionalCampaignPopup: false,
    customCampaignsIncluded: 0,
    customCampaignsPurchased: 0,
    customCampaignsProvisioned: 0,
    customCampaignsAvailableToProvision: 0,
    prebuiltCampaignsIncluded: 0,
    prebuiltCampaignsProvisioned: 0,
    prebuiltCampaignsAvailableToProvision: 0,
    campaignsAvailableToDelete: 0,

    //searchInput:null,
    itemsPerPage: 5,
    mainActivetab: 0,
    addCampaignPopup: false,
    mode: "",
    campaignId: 0,
    campaigns: [],
    campaignsMoreOrLessArr: [],
    statesOptions: [],
    countriesOptions: [],
    revenueRangeOptions: [],
    employeeSizeOptions: [],
    industryOptions: [],
    term: "",
    type: "",

    campaignType: 1, // 1: Custom and 2: Prebuilt
    prebuiltCampaignOverWarning: false,
    prebuiltCampaignsList: [],
    prebuiltCampaignsMoreOrLessArr:[],
    prebuiltCampaignPerPage: 5,
    recordList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "25", value: 25 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
    ],
    prebuiltCampaignId: 0,
    worker: 1,

    userActionType: 'add',
    addCustomCampaignRadioColor: 'success',
    addCustomCampaignTextColor: 'text-success',
    additionalCustomerCampaign : 1,
    additionalCustomCampaignOptions: [],


    deleteCustomCampaignRadioColor: 'secondary',
    deletionCustomCampaignTextColor: '',
    deletionCustomCampaign: 1,
    deletionCustomCampaignOptions: [],

    addDeleteCustomCampaignConfirmActive: false,

    //Add campaign
    //general Tab
    campaignName: "",
    description: "",
    crawlerOptions: [],
    crawlerPurchaseMessagePopupActive: false,

    //Triggers Tab
    keywordsOptions: [],
    keywordsOptionsArray: [],
    keywordSelected: null,
    keywordAdded: [],
    keywordToshow: [],
    keywordBasedCampaigns: [],
    keywordBasedCampaignsPopup: false,
    compititorsOptions: [],
    compititorsOptionsArray: [],
    competitorsSelected: null,
    competitorsAdded: [],
    competitorsToshow: [],
    searchWord: null,
    tempSearchWord: null,

    categoriesOption: [],
    categoriesSelected: null,
    categoriesAdded: [],
    categoriesToShow: [],

    //Filter Tab
    statesAdded: [],
    statesSelect: null,
    statesToShow: [],

    countriesAdded: [],
    countriesSelected: null,
    countriesToShow: [],
    
    employeeSizeSelected: null,
    employeesAdded: [],
    employeeToShow: [],
    
    revenueSelected: null,
    revenueAdded: [],
    revenueToShow: [],
    
    industriesAdded: [],
    industriesSelected: null,
    industriesToShow: [],
    
    excludeIndutriesAdded: [],
    excludeIndustriesSelected: null,
    excludeIdustriesToShow: [],
    
    postalCodes: null,
    excludeCurrentCustomer: null,
    excludeDomains: null,

    //contact Tab
    jobsAdded: [],

    //Delete
    deleteCampaignPopupActive: false,
    deleteCampaignName: "",

    //notification
    emailMonthlyreport: null,
    emailDailyreport: null,
    emailWeeklyreport: null,
    dailyEmails: [
      {
        demail: null,
      },
    ],
    weeklyEmails: [
      {
        wemail: null,
      },
    ],
    monthlyEmails: [
      {
        memail: null,
      },
    ]
  }),
  created() {
    this.getListOfCampagins();
    this.getCampaignsPreBuilt();
    this.listContactStates();
    this.listCompanyEmployeeSizes();
    this.listContactCountries();
    this.listCompanyRevenueBands();
    this.listCompanyIndustries();
    this.campaignCountSummary();
    this.generateAdditionalUserOptions();
  },
  mounted() {
      let that = this;
      setTimeout(() => {
      document.querySelector("#tableDataPrebuiltCampaign .input-search").addEventListener("keyup", function() {
          that.excelData = that.filterExport(true);
          });
      }, 1000);
      let uid = this.$route.params.id;
      let VVtoken = localStorage.getItem("VVtoken");
      let token = JSON.parse(VVtoken);
      this.isReseller = token[uid].isReseller
      this.isActiveFreeTrial = token[uid].isActiveFreeTrial
  },
  watch: {
    keywordsOptionsArray(){
      if(this.keywordsOptionsArray.length > 0){
        this.keywordsOptions = this.keywordsOptionsArray.reduce(function(a, b) {
          return a.length <= b.length ? a : b;
        })
      }
    },
    compititorsOptionsArray(){
      if(this.compititorsOptionsArray.length > 0){
        this.compititorsOptions = this.compititorsOptionsArray.reduce(function(a, b) {
          return a.length <= b.length ? a : b;
        })
      }
    }
  },
  computed: {
    selectedKeyword: function() {
      return this.competitorsToshow.length + this.keywordToshow.length + this.categoriesToShow.length
    }
  },
  methods: {
    changeKeywordsMoreLess(value, i, type) {
      // this.campaignsMoreOrLessArr[i] = value;
      if(type == 'custom'){
        Vue.set(this.campaignsMoreOrLessArr,i,value);
      }else{
        Vue.set(this.prebuiltCampaignsMoreOrLessArr,i,value);
      }
      // Vue.set(this.campaigns, i, {
      //   keywordsMoreLess: value
      // });
    },
    // when we click on back button in add/edit campaign
    stepBack() {
      this.mainActivetab = this.mainActivetab - 1;
      // this.crawlerPurchaseMessagePopupActive = this.mainActivetab == 1 && this.customCampaignsAvailableToProvision <= 0 && this.campaignType == 1 ? true : false
    },
    // when we click on next button in add/edit campaign
    nextStep() {
      if (this.mainActivetab == 1) {
        this.$validator.validateAll("campaignValidate").then(async (result) => {
          if (result) {
            this.mainActivetab = this.mainActivetab + 1;
          }
        });
      } else if (this.mainActivetab == 2) {
        this.$validator.validateAll("triggerValidate").then(async (result) => {
          if (result) {
            this.mainActivetab = this.mainActivetab + 1;
          }
        });
      } else if (this.mainActivetab == 4) {
        this.addEditCampaign();
      } else {
        this.mainActivetab = this.mainActivetab + 1;
      }
      setTimeout(() => {
        this.crawlerPurchaseMessagePopupActive = this.mainActivetab == 1 && this.customCampaignsAvailableToProvision <= 0 && this.campaignType == 1 ? true : false
      }, 100);
    },
    async campaignCountSummary(){
      this.$vs.loading();
      await this.axios.get("/ws/FindAnyoneCampaign/CampaignCountSummary").then(response => {
        let data = response.data;
        this.customCampaignsIncluded = data.Custom_Campaigns_Included
        this.customCampaignsPurchased = data.Custom_Campaigns_Purchased
        this.customCampaignsProvisioned = data.Custom_Campaigns_Provisioned
        this.customCampaignsAvailableToProvision = data.Custom_Campaigns_Available_To_Provision
        // this.customCampaignsAvailableToProvision = 0
        this.prebuiltCampaignsIncluded = data.Prebuilt_Campaigns_Included
        // this.prebuiltCampaignsIncluded = 0
        this.prebuiltCampaignsProvisioned = data.Prebuilt_Campaigns_Provisioned
        this.prebuiltCampaignsAvailableToProvision = data.Prebuilt_Campaigns_Available_To_Provision
        this.crawlerOptions = []
        for (let i = 0; i < this.customCampaignsAvailableToProvision; i++) {
          this.crawlerOptions.push({Key: i+1, Value: (i+1).toString()})
        }
        this.generateDeletionCustomCampaignOptions()
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });  
    },
    //get list of campaigns to show
    async getListOfCampagins() {
      this.$vs.loading();
      await this.axios
        .get("/ws/FindAnyoneCampaign/GetCampaigns")
        .then((response) => {
          let data = response.data;
          let tempCampaigns = data;
          let that = this
          tempCampaigns.map(function (manipulatedData) {
            let keywordArr = [];
            for (let i = 0; i < manipulatedData.Items.length; i++) {
              if (manipulatedData.Items[i].Value) {
                keywordArr.push(manipulatedData.Items[i].Value);
              }
            }
            let keywordStr = keywordArr.toString();
            manipulatedData.keywords = keywordStr.replace(/,/g, ", ");
            manipulatedData.keywordsMoreLess = "less";
            manipulatedData.showMoreLessIcon = manipulatedData.keywords.split(",").length > 9 ? true : false;
            manipulatedData.type = manipulatedData.PrebuiltCampaign_Id == 0 ? 'Custom' : 'Prebuilt'
            that.campaignsMoreOrLessArr.push("less")
            return manipulatedData;
          });
          this.campaigns = tempCampaigns
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    getCampaignsPreBuilt() {
      this.$vs.loading();
      this.axios.get("/ws/FindAnyoneCampaign/GetCampaignsPreBuilt").then((response) => {
        let data = response.data;
        let tempPrebuiltCampaignsList = data;
        let that = this
        tempPrebuiltCampaignsList.map(function (manipulatedData) {
          let keywordArr = [];
          for (let i = 0; i < manipulatedData.Items.length; i++) {
            if (manipulatedData.Items[i].Value) {
              keywordArr.push(manipulatedData.Items[i].Value);
            }
          }
          let keywordStr = keywordArr.toString();
          manipulatedData.keywords = keywordStr.replace(/,/g, ", ");
          manipulatedData.keywordsMoreLess = "less";
          manipulatedData.showMoreLessIcon = manipulatedData.keywords.split(",").length > 2 ? true : false;
          that.prebuiltCampaignsMoreOrLessArr.push("less")
          return manipulatedData;
        });
        this.prebuiltCampaignsList = tempPrebuiltCampaignsList
        this.excelData = this.filterExport();
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    filterExport(search = false) {
        let data = [];
        let prebuiltCampaignData = !search ? this.prebuiltCampaignsList : this.$refs.tableDataPrebuiltCampaign.datax;

        for (var i = 0; i < prebuiltCampaignData.length; i++) {
            data.push({
                "Campaign_Name": prebuiltCampaignData[i].Campaign_Name,
                "Category": prebuiltCampaignData[i].Category,
                "Description": prebuiltCampaignData[i].Description,
                "keywords": prebuiltCampaignData[i].keywords,
            });
        }
        return data;
    },
    selectPrebuiltCampaign(data) {
      this.campaignName = data.Campaign_Name;
      this.description = data.Description;
      this.prebuiltCampaignId = data.Id;
      this.competitorsAdded = [];
      this.competitorsToshow = [];
      this.categoriesAdded = [];
      this.categoriesToShow = [];
      this.keywordAdded = [];
      this.keywordToshow = [];

      if (data.Items.length > 0) {
        data.Items.forEach((e) => {
          if (e.Type == "competitor") {
            this.competitorsAdded.push(e);
            if (e.Requested) {
              this.competitorsToshow.push(e.Value + " (REQUESTED)");
            } else {
              this.competitorsToshow.push(e.Value);
            }
          } else if (e.Type == "category") {
            this.categoriesAdded.push(e);
            if (e.Requested) {
              this.categoriesToShow.push(e.Value + " (REQUESTED)");
            } else {
              this.categoriesToShow.push(e.Value);
            }
          } else {
            this.keywordAdded.push(e);
            if (e.Requested) {
              this.keywordToshow.push(e.Value + " (REQUESTED)");
            } else {
              this.keywordToshow.push(e.Value);
            }
          }
        });
      }
    },
    // get the keyworks and competitors by searching in input field
    async getTiggerItemByTypeTerm(type, event) {
      if (event.keyCode != 8 && event.keyCode != 46 && event.keyCode != 32) {
        let term = "";
        if (type == "keyword") {
          term = this.$refs.keyworddd.search;
          this.searchWord = this.$refs.keyworddd.search;
        } else if (type == "category") {
          term = this.$refs.categories.search;
          this.searchWord = this.$refs.categories.search;
        } else {
          term = this.$refs.competitors.search;
          this.searchWord = this.$refs.competitors.search;
        }
        let input = {
          Type: type,
          Term: term.trim().toLowerCase(),
        };
        if (term) {
          this.$vs.loading();
          await this.axios
            .post("/ws/FindAnyoneCampaign/GetTriggerItemsByTypeTerm", input)
            .then((response) => {
              let data = response.data;
              if (type == "keyword") {
                if(!this.searchWord.includes(this.tempSearchWord) || !this.tempSearchWord.includes(term)){
                  this.keywordsOptionsArray = []
                  this.tempSearchWord = this.searchWord
                }
                this.keywordsOptionsArray.push(data);
              } else if (type == "category") {
                this.categoriesOption = data;
              } else {
                if(!this.searchWord.includes(this.tempSearchWord) || !this.tempSearchWord.includes(term)){
                  this.compititorsOptionsArray = []
                  this.tempSearchWord = this.searchWord
                }
                this.compititorsOptionsArray.push(data);
              }
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
              this.showError(e);
            });
        }
      }else{
        this.keywordsOptionsArray = [];
        this.compititorsOptionsArray = [];
      }
    },
    //get list of states
    listContactStates() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListContactStates")
        .then((response) => {
          let data = response.data;
          this.statesOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //get list of countries
    async listContactCountries() {
      this.$vs.loading();
      await this.axios
        .get("/ws/FindAnyoneSearchContacts/ListContactCountries")
        .then((response) => {
          let data = response.data;
          this.countriesOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    setUnitedStatesDefault(){
      for (let i = 0; i < this.countriesOptions.length; i++) {
        if(this.countriesOptions[i].Key == 'United States'){
          this.countriesAdded.push(this.countriesOptions[i])
          this.countriesToShow.push(this.countriesOptions[i].Value)
        }
      }
    },
    //get list of employee sizes
    listCompanyEmployeeSizes() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListCompanyEmployeeSizes")
        .then((response) => {
          let data = response.data;
          this.employeeSizeOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //get list of revenues
    listCompanyRevenueBands() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListCompanyRevenueBands")
        .then((response) => {
          let data = response.data;
          this.revenueRangeOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //get list of industries
    listCompanyIndustries() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListCompanyIndustries")
        .then((response) => {
          let data = response.data;
          this.industryOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    // call api for add/edit campaign
    async addEditCampaign() {
      let item = [];
      if (this.keywordAdded.length > 0) {
        this.keywordAdded.forEach((element) => {
          item.push(element);
        });
      }
      if (this.competitorsAdded.length > 0) {
        this.competitorsAdded.forEach((element) => {
          item.push(element);
        });
      }
      if (this.categoriesAdded.length > 0) {
        this.categoriesAdded.forEach((element) => {
          item.push(element);
        });
      }
      let input = {
        Id: this.campaignId,
        Campaign_Name: this.campaignName,
        Description: this.description,
        Items: item,
        workers: this.worker,
        PrebuiltCampaign_Id: this.prebuiltCampaignId
      };
      this.$vs.loading();
      let resp = await this.axios
        .post(this.mode == "add" ? "/ws/FindAnyoneCampaign/AddCampaign" : "/ws/FindAnyoneCampaign/EditCampaign",input)
        .then((response) => {
          let data = response.data;
          this.campaignId = data.Id;
          this.campaignCountSummary()
          this.$vs.loading.close();
          return true;
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
          return false;
        });
      if (resp) {
        if (this.mode == "edit") {
          this.addTriggerToCampaign();
        }
        this.addCampaignFilter();
        this.addContactFilter();
        this.updateNoticationSetting();
      }
    },
    // Delete Campaign
    async deleteCampaign() {
      this.$vs.loading();
      await this.axios
        .post("/ws/FindAnyoneCampaign/DeleteCampaign?Id=" + this.campaignId)
        .then((response) => {
          let data = response.data;
          if (data.Transaction_Successful) {
            this.deleteCampaignName = "";
            this.deleteCampaignPopupActive = false;
            this.campaignCountSummary()
            this.campaignId = 0;
            this.$vs.notify({
              title: "Success",
              text: "Campaign  Deleted Successfully",
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });

            this.getListOfCampagins();
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    // add triggers(keyword and competitors) to campaign api
    addTriggerToCampaign() {
      let trigger = [];
      if (this.keywordAdded.length > 0) {
        this.keywordAdded.forEach((e) => {
          trigger.push(e.Id);
        });
      }
      if (this.competitorsAdded.length > 0) {
        this.competitorsAdded.forEach((e) => {
          trigger.push(e.Id);
        });
      }
      if (this.categoriesAdded.length > 0) {
        this.categoriesAdded.forEach((e) => {
          trigger.push(e.Id);
        });
      }
      let input = {
        CampaignId: this.campaignId,
        TriggerIds: trigger,
      };
      this.$vs.loading();
      this.axios
        .post("/ws/FindAnyoneCampaign/AddTriggerItemToCampaign", input)
        .then((response) => {
          let data = response.data;
          if (data.Transaction_Successful) {
            this.$vs.notify({
              title: "Success",
              text: "Triggers Added Successfully",
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: data.Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    clearSearchedTerm(type){
      if(type == 'keyword'){
        this.$refs.keyworddd.search = ""
      }else{
        this.$refs.competitors.search = ""
      }
      this.searchWord = null;
    },
    // request a new keyword or competitor api
    requestNewTrigger(type) {
      if (this.searchWord) {
        let input = {
          CampaignId: this.mode == 'edit' ? this.campaignId : 0,
          Type: type,
          Value: this.searchWord.trim().toLowerCase(),
        };
        this.$vs.loading();
        this.axios
          .post("/ws/FindAnyoneCampaign/RequestNewTriggerItemToCampaign", input)
          .then((response) => {
            let data = response.data;
            if (type == "keyword") {
              if (data.Value) {
                this.keywordAdded.push(data);
                this.keywordToshow.push(data.Value + " (REQUESTED)");
                this.$refs.keyworddd.search = ""
                this.$refs.competitors.search = ""
                this.searchWord = null;
              }
            } else {
              if (data.Value) {
                this.competitorsAdded.push(data);
                this.competitorsToshow.push(data.Value + " (REQUESTED)");
                this.$refs.competitors.search = ""
                this.$refs.keyworddd.search = ""
                this.searchWord = null;
              }
            }
            this.$vs.loading.close();
          })
          .catch((e) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
            this.showError(e);
          });
      }
    },
    // add filters to campaign api
    addCampaignFilter() {
      let states = [];
      if (this.statesAdded.length > 0) {
        this.statesAdded.forEach((e) => {
          states.push({ Text: e.Key });
        });
      }
      let countries = [];
      if (this.countriesAdded.length > 0) {
        this.countriesAdded.forEach((e) => {
          countries.push({ Text: e.Key });
        });
      }
      let industries = [];
      if (this.industriesAdded.length > 0) {
        this.industriesAdded.forEach((e) => {
          industries.push({ Text: e.Key });
        });
      }
      let revenues = [];
      if (this.revenueAdded.length > 0) {
        this.revenueAdded.forEach((e) => {
          revenues.push({ Text: e.Key });
        });
      }
      let employees = [];
      if (this.employeesAdded.length > 0) {
        this.employeesAdded.forEach((e) => {
          employees.push({ Text: e.Key });
        });
      }
      let postalCodes = [];
      let postalCodeArr = this.postalCodes ? this.postalCodes.split("\n") : [];
      if (postalCodeArr.length > 0) {
        postalCodeArr.forEach((e) => {
          if (e) {
            postalCodes.push({ Text: e });
          }
        });
      }
      let exclude = [];
      let excludecurrent = this.excludeCurrentCustomer ? this.excludeCurrentCustomer.split("\n") : [];
      if (excludecurrent.length > 0) {
        excludecurrent.forEach((e) => {
          if (e) {
            exclude.push({ Text: e });
          }
        });
      }
      let excludeind = [];
      if (this.excludeIndutriesAdded.length > 0) {
        this.excludeIndutriesAdded.forEach((element) => {
          excludeind.push({ Text: element.Key });
        });
      }
      let excludeDomain = [];
      let excludedomains = this.excludeDomains ? this.excludeDomains.split("\n") : [];
      if (excludedomains.length > 0) {
        excludedomains.forEach((e) => {
          if (e) {
            excludeDomain.push({ Text: e });
          }
        });
      }
      let input = {
        CampaignID: this.campaignId,
        States: states,
        Countries: countries,
        Industries: industries,
        RevenueRange: revenues,
        EmployeeSize: employees,
        ExcludeCurrentCustomers: exclude,
        ExcludeIndustries: excludeind,
        ExcludeDomains: excludeDomain,
        PostalCodes: postalCodes
      };
      this.$vs.loading();
      this.axios
        .post("/ws/FindAnyoneCampaign/AddCampaignFilter", input)
        .then((response) => {
          let data = response.data;
          if (data.Transaction_Successful) {
            this.$vs.notify({
              title: "Success",
              text: "Fliters Added Successfully",
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: data.Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //add contact tab data in api
    addContactFilter() {
      let JobTitles = [];
      if (this.jobsAdded.length > 0) {
        for (let i = 0; i < this.jobsAdded.length; i++) {
          JobTitles.push({
            Text: this.jobsAdded[i],
          });
        }
      }
      let input = {
        CampaignID: this.campaignId,
        JobTitles: JobTitles,
      };
      this.$vs.loading();
      this.axios
        .post("/ws/FindAnyoneCampaign/AddContactFilter", input)
        .then((response) => {
          let data = response.data;
          if (data.Transaction_Successful) {
            this.$vs.notify({
              title: "Success",
              text: "Contacts Added Successfully",
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: data.Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //add keyword to show in input tag
    addKeywords() {
      if(this.campaignType == 1){
        this.checkKeywordForPreBuiltCampaigns(this.keywordSelected.Id)
      }
      if (this.keywordSelected) {
        if (this.keywordSelected.Requested) {
          this.keywordToshow.push(this.keywordSelected.Value + " (REQUESTED)");
        } else {
          this.keywordToshow.push(this.keywordSelected.Value);
        }
        this.keywordAdded.push(this.keywordSelected);

        this.keywordSelected = null;
        this.searchWord = null;
      }
    },
    checkKeywordForPreBuiltCampaigns(id){
      this.$vs.loading();
      this.axios.get("/ws/FindAnyoneCampaign/CheckKeywordForPreBuiltCampaigns?ID="+id).then((response) => {
        let data = response.data;
        this.keywordBasedCampaigns = data;
        if(this.keywordBasedCampaigns.length > 0){
          this.keywordBasedCampaignsPopup = true
        }
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
    closeKeywordBasedCampaignsPopup(){
      this.keywordBasedCampaigns = []
      this.keywordBasedCampaignsPopup = false
    },
    // add competitors to show in input tag
    addCompitotors() {
      if(this.campaignType == 1){
        this.checkKeywordForPreBuiltCampaigns(this.competitorsSelected.Id)
      }
      if (this.competitorsSelected) {
        if (this.competitorsSelected.Requested) {
          this.competitorsToshow.push(
            this.competitorsSelected.Value + " (REQUESTED)"
          );
        } else {
          this.competitorsToshow.push(this.competitorsSelected.Value);
        }
        this.competitorsAdded.push(this.competitorsSelected);
        this.competitorsSelected = null;
        this.searchWord = null;
      }
    },
    // add category to show in input tag
    addCategory() {
      if(this.campaignType == 1){
        this.checkKeywordForPreBuiltCampaigns(this.categoriesSelected.Id)
      }
      if (this.categoriesSelected) {
        if (this.categoriesSelected.Requested) {
          this.categoriesToShow.push(
            this.categoriesSelected.Value + " (REQUESTED)"
          );
        } else {
          this.categoriesToShow.push(this.categoriesSelected.Value);
        }
        this.categoriesAdded.push(this.categoriesSelected);
        this.categoriesSelected = null;
        this.searchWord = null;
      }
    },
    // add filter tab items to show in input tag
    addItemsToShow(type) {
      if (type == "state") {
        this.statesAdded.push(this.statesSelect);
        this.statesToShow.push(this.statesSelect.Value);
        this.statesSelect = null;
      } else if (type == "country") {
        this.countriesAdded.push(this.countriesSelected);
        this.countriesToShow.push(this.countriesSelected.Value);
        this.countriesSelected = null;
      } else if (type == "industry") {
        this.industriesAdded.push(this.industriesSelected);
        this.industriesToShow.push(this.industriesSelected.Value);
        this.industriesSelected = null;
      } else if (type == "revenue") {
        this.revenueAdded.push(this.revenueSelected);
        this.revenueToShow.push(this.revenueSelected.Value);
        this.revenueSelected = null;
      } else if (type == "excludeIdustries") {
        this.excludeIndutriesAdded.push(this.excludeIndustriesSelected);
        this.excludeIdustriesToShow.push(this.excludeIndustriesSelected.Value);
        this.excludeIndustriesSelected = null;
      }else {
        this.employeesAdded.push(this.employeeSizeSelected);
        this.employeeToShow.push(this.employeeSizeSelected.Value);
        this.employeeSizeSelected = null;
      }
    },
    /* call this function when we click on cancel button when we add or edit campaign */
    resetCampaignTypeSelection(campaignType){
      //general
      this.campaignType = campaignType
      this.campaignId = 0;
      this.campaignName = null;
      this.description = null;
      this.prebuiltCampaignId = 0;
      this.worker = 1;

      //trigger
      this.keywordsOptions = [];
      this.keywordsOptionsArray = [];
      this.keywordSelected = null;
      this.keywordAdded = [];
      this.keywordToshow = [];
      this.compititorsOptions = [];
      this.compititorsOptionsArray = [];
      this.competitorsSelected = null;
      this.competitorsAdded = [];
      this.competitorsToshow = [];
      this.categoriesAdded = [];
      this.categoriesOption = [];
      this.categoriesSelected = null;
      this.categoriesToShow = [];
      this.searchWord = null;

      //trigger
      this.statesSelect = null;
      this.statesToShow = [];
      this.statesAdded = [];

      this.countriesAdded = [];
      this.countriesSelected = null;
      this.countriesToShow = [];

      this.industriesToShow = [];
      this.industriesAdded = [];
      this.industriesSelected = null;

      this.revenueSelected = null;
      this.revenueAdded = [];
      this.revenueToShow = [];

      this.employeesAdded = [];
      this.employeeSizeSelected = null;
      this.employeeToShow = [];

      this.excludeIndutriesAdded = [];
      this.excludeIdustriesToShow = [];
      this.excludeIndustriesSelected = null;

      this.postalCodes = null;
      this.excludeCurrentCustomer = null;
      this.excludeDomains = null;

      //contacts
      this.jobsAdded = [];

      //notification
      this.emailMonthlyreport = false;
      this.emailDailyreport = false;
      this.emailWeeklyreport = false;

      this.dailyEmails = [
        {
          demail: null,
        },
      ];
      this.weeklyEmails = [
        {
          wemail: null,
        },
      ];
      this.monthlyEmails = [
        {
          memail: null,
        },
      ];
      this.setUnitedStatesDefault()
      setTimeout(() => {
        this.errors.clear();
      }, 100);
    },
    cancelCampaign() {
      //general
      this.mainActivetab = 0;
      this.addCampaignPopup = false;
      this.campaignId = 0;
      this.mode = "";
      this.campaignType = 1;
      this.campaignName = null;
      this.description = null;
      this.prebuiltCampaignId = 0;
      this.worker = 1;

      //trigger
      this.keywordsOptions = [];
      this.keywordsOptionsArray = [];
      this.keywordSelected = null;
      this.keywordAdded = [];
      this.keywordToshow = [];
      this.compititorsOptions = [];
      this.compititorsOptionsArray = [];
      this.competitorsSelected = null;
      this.competitorsAdded = [];
      this.competitorsToshow = [];
      this.categoriesAdded = [];
      this.categoriesOption = [];
      this.categoriesSelected = null;
      this.categoriesToShow = [];
      this.searchWord = null;

      //trigger
      this.statesSelect = null;
      this.statesToShow = [];
      this.statesAdded = [];

      this.countriesAdded = [];
      this.countriesSelected = null;
      this.countriesToShow = [];

      this.industriesToShow = [];
      this.industriesAdded = [];
      this.industriesSelected = null;

      this.revenueSelected = null;
      this.revenueAdded = [];
      this.revenueToShow = [];

      this.employeesAdded = [];
      this.employeeSizeSelected = null;
      this.employeeToShow = [];

      this.excludeIndutriesAdded = [];
      this.excludeIdustriesToShow = [];
      this.excludeIndustriesSelected = null;

      this.postalCodes = null;
      this.excludeCurrentCustomer = null;
      this.excludeDomains = null;

      //contacts
      this.jobsAdded = [];

      //notification
      this.emailMonthlyreport = false;
      this.emailDailyreport = false;
      this.emailWeeklyreport = false;

      this.dailyEmails = [
        {
          demail: null,
        },
      ];
      this.weeklyEmails = [
        {
          wemail: null,
        },
      ];
      this.monthlyEmails = [
        {
          memail: null,
        },
      ];
      setTimeout(() => {
        this.errors.clear();
      }, 100);
      this.getListOfCampagins();
    },
    // show data in popup to update the campaign
    editCampaign(data) {
      this.countriesAdded = []
      this.countriesToShow = []
      this.mainActivetab = 1;
      this.campaignName = data.Campaign_Name;
      this.description = data.Description;
      this.campaignId = data.Id;
      this.worker = data.workers
      this.campaignType = data.PrebuiltCampaign_Id == 0 ? 1 : 2
      this.prebuiltCampaignId = data.PrebuiltCampaign_Id
      this.mode = "edit";
      if (data.Items.length > 0) {
        data.Items.forEach((e) => {
          if (e.Type == "competitor") {
            this.competitorsAdded.push(e);
            if (e.Requested) {
              this.competitorsToshow.push(e.Value + " (REQUESTED)");
            } else {
              this.competitorsToshow.push(e.Value);
            }
          } else if (e.Type == "category") {
            this.categoriesAdded.push(e);
            if (e.Requested) {
              this.categoriesToShow.push(e.Value + " (REQUESTED)");
            } else {
              this.categoriesToShow.push(e.Value);
            }
          } else {
            this.keywordAdded.push(e);
            if (e.Requested) {
              this.keywordToshow.push(e.Value + " (REQUESTED)");
            } else {
              this.keywordToshow.push(e.Value);
            }
          }
        });
      }
      this.getFilterForCampaign(data.Id);
      this.getContactFilterForCampaign(data.Id);
      this.getNotificationSettings(data.Id);
      this.addCampaignPopup = true;
    },
    //get data of filter tab of that campaign id passed
    getFilterForCampaign(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneCampaign/GetFilterForCampaign?CampaignID=" + id)
        .then((response) => {
          let data = response.data;
          if (data.Countries.length > 0) {
            data.Countries.forEach((e) => {
              this.countriesOptions.forEach((cs) => {
                if (cs.Key == e.Text) {
                  this.countriesAdded.push(cs);
                  this.countriesToShow.push(cs.Value);
                }
              });
            });
          }
          if (data.States.length > 0) {
            data.States.forEach((e) => {
              this.statesOptions.forEach((cs) => {
                if (cs.Key == e.Text) {
                  this.statesAdded.push(cs);
                  this.statesToShow.push(cs.Value);
                }
              });
            });
          }
          if (data.Industries.length > 0) {
            data.Industries.forEach((e) => {
              this.industryOptions.forEach((industry) => {
                if (industry.Key == e.Text) {
                  this.industriesAdded.push(industry);
                  this.industriesToShow.push(industry.Value);
                }
              });
            });
          }
          if (data.RevenueRange.length > 0) {
            data.RevenueRange.forEach((e) => {
              this.revenueRangeOptions.forEach((rr) => {
                if (rr.Key == e.Text) {
                  this.revenueAdded.push(rr);
                  this.revenueToShow.push(rr.Value);
                }
              });
            });
          }
          if (data.EmployeeSize.length > 0) {
            data.EmployeeSize.forEach((e) => {
              this.employeeSizeOptions.forEach((es) => {
                if (es.Key == e.Text) {
                  this.employeesAdded.push(es);
                  this.employeeToShow.push(es.Value);
                }
              });
            });
          }
          if (data.PostalCodes.length > 0) {
            this.postalCodes = "";
            data.PostalCodes.forEach((e, i) => {
              if (i == data.PostalCodes.length - 1) {
                this.postalCodes += e.Text;
              } else {
                this.postalCodes += e.Text + "\n";
              }
            });
          }
          if (data.ExcludeCurrentCustomers.length > 0) {
            this.excludeCurrentCustomer = "";
            data.ExcludeCurrentCustomers.forEach((e, i) => {
              if (i == data.ExcludeCurrentCustomers.length - 1) {
                this.excludeCurrentCustomer += e.Text;
              } else {
                this.excludeCurrentCustomer += e.Text + "\n";
              }
            });
          }
          if (data.ExcludeIndustries.length > 0) {
            data.ExcludeIndustries.forEach((e) => {
              this.industryOptions.forEach((es) => {
                if (es.Key == e.Text) {
                  this.excludeIndutriesAdded.push(es);
                  this.excludeIdustriesToShow.push(es.Value);
                }
              });
            });
          }

          if (data.ExcludeDomains.length > 0) {
            this.excludeDomains = "";
            data.ExcludeDomains.forEach((e, i) => {
              if (i == data.ExcludeDomains.length - 1) {
                this.excludeDomains += e.Text;
              } else {
                this.excludeDomains += e.Text + "\n";
              }
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //get data of contact tab of that campaign id passed
    getContactFilterForCampaign(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneCampaign/GetContactFilterForCampaign?CampaignID=" + id)
        .then((response) => {
          let data = response.data;
          if (data.JobTitles.length > 0) {
            data.JobTitles.forEach((e) => {
              this.jobsAdded.push(e.Text);
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // to show confirmation popup for delete
    deleteConfirm(data) {
      this.deleteCampaignName = data.Campaign_Name;
      this.deleteCampaignPopupActive = true;
      this.campaignId = data.Id;
    },
    // cancel delete campaign
    cancelDeleteCampagin() {
      this.deleteCampaignName = "";
      this.deleteCampaignPopupActive = false;
      this.campaignId = 0;
    },
    //when we remove any keyword from input tag
    updateKeywordTags(value) {
      let arr = [];
      for (let i = 0; i < value.length; i++) {
        if (value[i].includes(" (REQUESTED)")) {
          let keyStr = value[i].substring(0, value[i].indexOf("("));
          arr.push(keyStr.trim());
        } else {
          arr.push(value[i]);
        }
      }
      this.keywordAdded.forEach((e, i) => {
        if (!arr.includes(e.Value)) {
          this.keywordAdded.splice(i, 1);
        }
      });
    },
    //when we remove any competitors from input tag
    updateCompetitorsTags(value) {
      let arr = [];
      for (let i = 0; i < value.length; i++) {
        if (value[i].includes(" (REQUESTED)")) {
          let keyStr = value[i].substring(0, value[i].indexOf("("));
          arr.push(keyStr.trim());
        } else {
          arr.push(value[i]);
        }
      }
      this.competitorsAdded.forEach((e, i) => {
        if (!arr.includes(e.Value)) {
          this.competitorsAdded.splice(i, 1);
        }
      });
    },
    updateCategoriesTags(value) {
      let arr = [];
      for (let i = 0; i < value.length; i++) {
        if (value[i].includes(" (REQUESTED)")) {
          let keyStr = value[i].substring(0, value[i].indexOf("("));
          arr.push(keyStr.trim());
        } else {
          arr.push(value[i]);
        }
      }
      this.categoriesAdded.forEach((e, i) => {
        if (!arr.includes(e.Value)) {
          this.categoriesAdded.splice(i, 1);
        }
      });
    },
    //when we remove any states from input tag
    updateStatesTags(value) {
      this.statesAdded.forEach((e, i) => {
        if (!value.includes(e.Value)) {
          this.statesAdded.splice(i, 1);
        }
      });
    },
    //when we remove any countries from input tag
    updateCoutriesTags(value) {
      this.countriesAdded.forEach((e, i) => {
        if (!value.includes(e.Value)) {
          this.countriesAdded.splice(i, 1);
        }
      });
    },
    //when we remove any industry from input tag
    updateIndustryTags(value) {
      this.industriesAdded.forEach((e, i) => {
        if (!value.includes(e.Value)) {
          this.industriesAdded.splice(i, 1);
        }
      });
    },
    //when we remove any revenue from input tag
    updateRevenueTags(value) {
      this.revenueAdded.forEach((e, i) => {
        if (!value.includes(e.Value)) {
          this.revenueAdded.splice(i, 1);
        }
      });
    },
    updateExcludeIndustriesTags(value){
      this.excludeIndutriesAdded.forEach((e, i) => {
        if (!value.includes(e.Value)) {
          this.excludeIndutriesAdded.splice(i, 1);
        }
      });
    },
    //when we remove any employee size from input tag
    updateEmplyoeeTags(value) {
      this.employeesAdded.forEach((e, i) => {
        if (!value.includes(e.Value)) {
          this.employeesAdded.splice(i, 1);
        }
      });
    },
    // when click on add email
    addEmail(type) {
      if (type === "D") {
        this.dailyEmails.push({
          demail: null,
        });
      } else if (type === "W") {
        this.weeklyEmails.push({
          wemail: null,
        });
      } else {
        this.monthlyEmails.push({
          memail: null,
        });
      }
    },
    // remove email
    removeEmail(type, index) {
      if (type === "D") {
        this.dailyEmails.splice(index, 1);
      } else if (type === "W") {
        this.weeklyEmails.splice(index, 1);
      } else {
        this.monthlyEmails.splice(index, 1);
      }
    },
    // update notifaction setting
    async updateNoticationSetting() {
      this.$vs.loading();
      let daily = [];
      let weekly = [];
      let monthly = [];
      this.dailyEmails.forEach((element) => {
        if (element.demail != null && element.demail != "") {
          daily.push(element.demail);
        }
      });
      this.weeklyEmails.forEach((element) => {
        if (element.wemail != null && element.wemail != "") {
          weekly.push(element.wemail);
        }
      });

      this.monthlyEmails.forEach((element) => {
        if (element.memail != null && element.memail != "") {
          monthly.push(element.memail);
        }
      });
      let input = {
        CampaignId: this.campaignId,
        EnableDailyRollup: this.emailDailyreport,
        EnableWeeklyRollup: this.emailWeeklyreport,
        EnableMonthlyRollup: this.emailMonthlyreport,
        DailyRollupEmails: daily,
        WeeklyRollupEmails: weekly,
        MonthlyRollupEmails: monthly,
      };
      await this.axios
        .post("/ws/FindAnyoneCampaign/UpdateNotificationSettings", input)
        .then((response) => {
          let data = response.data;
          if (data) {
            this.$vs.notify({
              title: "Success",
              text: "Notification Added Successfully",
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
          }
          setTimeout(() => {
            this.errors.clear();
          }, 50);
          this.cancelCampaign();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // get notifications emails of campaign id passed
    async getNotificationSettings(id) {
      this.$vs.loading();
      await this.axios
        .get("/ws/FindAnyoneCampaign/GetNotificationSettings?CampaignID=" + id)
        .then((response) => {
          let data = response.data;
          this.emailMonthlyreport = data.EnableMonthlyRollup;
          this.emailDailyreport = data.EnableDailyRollup;
          this.emailWeeklyreport = data.EnableWeeklyRollup;

          if (data.DailyRollupEmails.length > 0) {
            this.dailyEmails = [];
            data.DailyRollupEmails.forEach((element) => {
              this.dailyEmails.push({
                demail: element,
              });
            });
          }
          if (data.WeeklyRollupEmails.length > 0) {
            this.weeklyEmails = [];
            data.WeeklyRollupEmails.forEach((element) => {
              this.weeklyEmails.push({
                wemail: element,
              });
            });
          }
          if (data.MonthlyRollupEmails.length > 0) {
            this.monthlyEmails = [];
            data.MonthlyRollupEmails.forEach((element) => {
              this.monthlyEmails.push({
                memail: element,
              });
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    generateDeletionCustomCampaignOptions(){
      this.deletionCustomCampaignOptions = []
      this.campaignsAvailableToDelete = 0;
      if(this.customCampaignsPurchased > 0 && this.customCampaignsProvisioned > 0){
        this.campaignsAvailableToDelete = (this.customCampaignsPurchased) - (this.customCampaignsProvisioned - this.customCampaignsIncluded)
      }else{
        this.campaignsAvailableToDelete = this.customCampaignsPurchased
      }
      for (let i = 1; i <= this.campaignsAvailableToDelete; i++) {
          this.deletionCustomCampaignOptions.push(i)
      }
    },
    generateAdditionalUserOptions(){
      this.additionalCustomCampaignOptions = []
      for (let i = 1; i <= 10; i++) {
          this.additionalCustomCampaignOptions.push(i)
      }
    },
    changeColor(){
      if(this.userActionType == 'add'){
        this.addCustomCampaignRadioColor = 'success'
        this.addCustomCampaignTextColor = 'text-success'
        this.deleteCustomCampaignRadioColor = 'secondary'
        this.deletionCustomCampaignTextColor = ''
      }else{
        this.addCustomCampaignRadioColor = 'secondary'
        this.addCustomCampaignTextColor = ''
        this.deleteCustomCampaignRadioColor = 'danger'
        this.deletionCustomCampaignTextColor = 'text-danger'
      }
    },
    cancelPurchase(){
      this.purchaseAdditionalCampaignPopup = false

      this.userActionType = 'add'
      this.addCustomCampaignRadioColor = 'success'
      this.addCustomCampaignTextColor = 'text-success'
      this.additionalCustomerCampaign = 1
      // this.additionalCustomCampaignOptions = []

      this.deleteCustomCampaignRadioColor = 'secondary'
      this.deletionCustomCampaignTextColor = ''
      this.deletionCustomCampaign = 1
      // this.deletionCustomCampaignOptions = []
    },
    purchaseAdditionalCampaign(){
      this.$vs.loading();
      this.axios.get("/ws/FindAnyoneCampaign/PurchaseAdditionalCampaign?qty="+this.additionalCustomerCampaign).then(() => {
        this.$vs.notify({
          title:'Success',
          text: 'Your additional campaign have been updated successfully.',
          color: "success",
          iconPack: 'feather',
          icon:'icon-check',
          position: "top-right",
          time:4000
        });
        this.addDeleteCustomCampaignConfirmActive = false
        this.cancelPurchase()
        this.campaignCountSummary()
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.$vs.notify({
          title:'Error',
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: 'feather',
          icon:'icon-alert-circle',
          position:"top-right",
          time:4000
        });
        this.showError(e);
      });  
    },
    deletePurchasedCampaign(){
      this.$vs.loading();
      this.axios.get("/ws/FindAnyoneCampaign/RemoveAdditionalCampaign?qty="+this.deletionCustomCampaign).then(() => {
        this.$vs.notify({
          title:'Success',
          text: 'Your additional campaign have been updated successfully.',
          color: "success",
          iconPack: 'feather',
          icon:'icon-check',
          position: "top-right",
          time:4000
        });
        this.addDeleteCustomCampaignConfirmActive = false
        this.cancelPurchase()
        this.campaignCountSummary()
        this.campaignCountSummary
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.$vs.notify({
          title:'Error',
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: 'feather',
          icon:'icon-alert-circle',
          position:"top-right",
          time:4000
        });
        this.showError(e);
      });
    }
  },
};
</script>